import { ChangeEvent, FocusEvent, useState, MouseEvent, useEffect, useRef, useCallback, MutableRefObject } from 'react';
import { ariaAttributeProps, dataAttributeProps } from '../../../utils/ComponentUtils';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import useSlot from '../../../hooks/useSlots';
import Tooltip from '../Tooltip';
import InfoIcon from '../icon/InfoIcon';

export type MultiTextFieldProps = {
  innerRef?: MutableRefObject<HTMLTextAreaElement | null>;
  value?: string;
  name?: string;
  id?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  rows?: number;
  dynamicHeight?: boolean;
  errorState?: boolean;
  maxLength?: number;
  hideMaxLengthCounter?: boolean;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onClick?: (event: MouseEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (event: KeyboardEvent) => void;
  helpText?: string;
  required?: boolean;
};

const MultiTextField = withSlot<MultiTextFieldProps, SlotDefinitions<['trailing']>>((props) => {
  const {
    value,
    id,
    placeholder,
    label,
    className,
    disabled,
    onChange,
    onFocus,
    onBlur,
    name,
    rows = 3,
    dynamicHeight,
    onClick,
    onKeyPress,
    innerRef,
    errorState,
    maxLength,
    helpText,
    required,
    hideMaxLengthCounter,
  } = props;
  const internalRef = useRef<HTMLTextAreaElement | null>(null);
  const ref = innerRef || internalRef;

  const [textHeight, setTextHeight] = useState({ height: 40 });
  useEffect(() => {
    if (ref.current && dynamicHeight) {
      ref.current.style.height = textHeight.height + 'px';
    }
  });
  const calHeight = useCallback(() => {
    // only run if dynamic height is set
    const el = ref.current;
    if (dynamicHeight && el) {
      // Calculate and set height...
      el.style.height = '0px';
      const newHeight = Math.min(256, el.scrollHeight); // to match max-h-64 class
      setTextHeight({ height: newHeight });
    }
  }, [dynamicHeight, ref]);

  const onTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    calHeight();
    // Call the passed function if there is one
    onChange && onChange(event);
  };

  const hasLabel = label && label.length > 0;

  useEffect(() => {
    calHeight();
  }, [calHeight]);

  const inputHasLabelClasses = hasLabel ? 'mt-9' : '';

  const onInputFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    onFocus && onFocus(event);
  };

  const onInputBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    onBlur && onBlur(event);
  };

  useEffect(() => {
    if (!onKeyPress) {
      return;
    }

    const handler = (event: KeyboardEvent) => {
      onKeyPress(event);
    };
    const textAreaRef = ref.current;

    textAreaRef?.addEventListener('keydown', handler);

    return () => {
      textAreaRef?.removeEventListener('keydown', handler);
    };
  }, [onKeyPress, ref]);

  const trailingSlot = useSlot(props, 'trailing');

  const slotClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      ref.current?.focus();
    },
    [ref],
  );

  return (
    <div
      className={`relative flex w-full items-center ${inputHasLabelClasses} ${disabled ? 'pointer-events-none opacity-50' : ''}`}
      data-cy="multi-text-field"
    >
      {label && label.length > 0 && (
        <div className={`absolute left-0 top-0 ${helpText ? '-mt-7' : '-mt-5'}`}>
          <div className="flex items-center gap-2">
            <label
              data-cy="text-area-label"
              htmlFor={id}
              className={`text-primary-1 text-dpm-12 left-0 top-0 transition-opacity duration-150 ease-in-out`}
            >
              {required && <span className="text-semantic-3 pr-1 font-semibold">*</span>}
              {label}
            </label>
            {!!helpText && (
              <Tooltip text={helpText}>
                {(tooltip) => (
                  <div {...tooltip}>
                    <InfoIcon className="h-5 w-5" />
                  </div>
                )}
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div
        className={`w-full rounded-lg border-2 ${
          errorState ? 'border-red-500' : 'border-primary-1 [&:has(textarea:focus)]:border-accent-1'
        } disabled:border-gray-4 bg-white`}
      >
        <textarea
          data-cy="text-area"
          {...dataAttributeProps(props)}
          {...ariaAttributeProps(props)}
          id={id}
          onClick={onClick}
          name={name}
          ref={(r) => (ref.current = r)}
          rows={rows}
          disabled={disabled}
          onChange={onTextChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          className={`${
            rows === 1 && 'resize-none overflow-hidden'
          } b-0 disabled:text-color-3 placeholder-gray-2 text-primary-1 -mb-[6px] h-full w-full rounded-lg p-2 leading-7 outline-none ${className}`}
          placeholder={placeholder}
          value={value}
          data-testid="multitext-element"
          maxLength={maxLength}
        />
        <div className="-mt-1 mb-1 flex cursor-text justify-end empty:hidden" onMouseDown={slotClick}>
          {maxLength && !hideMaxLengthCounter && (
            <span className="text-gray-2 text-dpm-14 px-2 pt-1">
              {value?.length}/{maxLength}
            </span>
          )}
          {trailingSlot()}
        </div>
      </div>
    </div>
  );
});

export default MultiTextField;
